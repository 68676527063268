.hero-header {
    background: linear-gradient(rgba(2, 71, 19, 0.85), rgba(62, 26, 9, 0.85)), url(../../assets/banner-1.jpg) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 400px;
    align-content: center;
    border-radius: 14px;
}
.text-header{
    color: white;
    font-size: 24px;
    font-weight: 600;
    font-family: "poppins";
    padding: 10px;
    padding-top: 100px;
}

.gt-body {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background: rgba(249, 249, 249, 1);
    border: 1px solid rgba(225, 225, 225, 1.00);
    box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
}
.gt-panel-border-green {
    border-top: 8px solid #499202;
    padding: 15px;
    margin-bottom: 1px;
    background: rgba(249, 249, 249, 1);
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 5px 5px 0px 0px;
}
.gt-panel-border-orange {
    border-top: 8px solid #e47203;
    padding: 15px;
    margin-bottom: 1px;
    background: rgba(249, 249, 249, 1);
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 5px 5px 0px 0px;
}
.gt-panel.gt-panel-orange .gt-panel-head {
    background: #e47203;
    color: rgba(255, 255, 255, 1.00);
}
.gt-panel.gt-panel-green .gt-panel-head {
    background: #499202;
    color: rgba(255, 255, 255, 1.00);
}
.gt-panel.gt-panel-default .gt-panel-head {
    background: #f2f2f2;
    color: #353535;
    border-bottom: 0px;
    width: 100%;
    float: left;
    border: 1px solid #DDDDDD;
    border-bottom: 0px;
}
.gt-panel.gt-panel-blue .gt-panel-head {
    background-color: rgb(8, 156, 190);
    color: white;
    border-color: rgb(8, 156, 190);
    border-bottom: 0px;
}
.gt-panel {
    margin-bottom: 20px;
    width: 100%;
    float: left;
}
.gt-panel .gt-panel-head {
    padding: 15px;
}
.gt-panel .gt-panel-head .gt-panel-title,
.gt-panel .gt-panel-border-green .gt-panel-title {
    font-size: 16px;
}
.gt-panel .gt-panel-head .gt-panel-title,
.gt-panel .gt-panel-border-orange .gt-panel-title {
    font-size: 16px;
}
.gt-panel .gt-panel-body {
    padding: 10px 15px;
    border-top: none !important;
    background: rgba(249, 249, 249, 1);
    box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    -webkit-box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    -moz-box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    width: 100%;
    float: left;
    border: 1px solid #DDDDDD;
}
.grey-bg {  
    background-color: #F5F7FA;
}
.attribution { 
    font-size: 11px; text-align: center; 
    background-color: hsl(240, 5%, 91%);
    padding: 8px 0 5px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }  
  .attribution a { color: hsl(228, 45%, 44%); }
  .card-wrapper {
    min-height: 100vh;
    padding-bottom: 50px; 
    padding-top: 25px;
  }

  h2 {
    margin: 12px 0;
  }  
  .content-cc{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .faq-card {  
    background-color: #fff;
    border-radius: 20px;  
    margin-top: 90px;    
    box-shadow: 0px 60px 50px -25px rgba(0, 0, 0, 0.35);
  }
    .faq-ilustrations{
    position: relative;
  }  
  .faq-ilustrations .mobile.ilust{
    position: absolute;
    top:-110px;
    left:-30px;
    right: 0;
  }  
  .mobile{
    display: block;
    margin: 0 auto;
  }
  .faq-content {
    padding: 9px 25px 3rem; 
  }  
  .faq-content h1 {
    font-size: 32px;
    text-align: center;
      }
 .faq-accordion {
    padding: 8px 0;
    border-bottom: 1px solid hsl(240, 5%, 91%);
  }
  input.tgg-title {
    appearance: unset;
    all:unset;
  }
  .faq-accordion-title label{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .faq-accordion-title h2{
    font-size: 12px;
    font-weight: 400;
  }  
  .faq-accordion-title span{
    margin-left: auto;
    transition: transform .4s ease-in-out;
  }
  .faq-accordion-content {
    color: var(--neutral-soft-color);
    overflow: hidden;
    max-height: 0;
    transition: max-height .4s ease-in-out;
  }
  .faq-accordion-title:hover h2{
    color: var(--primary-soft-color)
  }
.faq-accordion .tgg-title:checked + div>label>h2 {
    font-weight: 700;
  }
.faq-accordion .tgg-title:checked + div>label>span {
    will-change: transform;
    transform: rotate(180deg);
  }
.faq-accordion .tgg-title:checked ~ .faq-accordion-content{
    will-change: max-height;
    max-height: 1000px;
  }
  .text_custom {
    color: green;
    font-size: 20px;
    font-weight: 600;
    font-family: "poppins";
}
.text-paragraph {
    font-size: 16px;
    font-weight: 300;
    font-family: "poppins";
    color:black;
    text-align: justify;
    padding-left: 10px;
}
.question_header{
    color: black;
    font-size: 16px;
    font-weight: 400;
    font-family: "poppins";
    padding-left: 10px;
}