.hero-header {
    background: linear-gradient(rgba(2, 71, 19, 0.85), rgba(62, 26, 9, 0.85)), url(../../assets/banner-1.jpg) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 400px;
    align-content: center;
    border-radius: 14px;
}
.text-header{
    color: white;
    font-size: 24px;
    font-weight: 600;
    font-family: "poppins";
    padding: 10px;
    padding-top: 100px;
}

.gt-body {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background: rgba(249, 249, 249, 1);
    border: 1px solid rgba(225, 225, 225, 1.00);
    box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
}
.gt-panel-border-green {
    border-top: 8px solid #499202;
    padding: 15px;
    margin-bottom: 1px;
    background: rgba(249, 249, 249, 1);
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 5px 5px 0px 0px;
}
.gt-panel-border-orange {
    border-top: 8px solid #e47203;
    padding: 15px;
    margin-bottom: 1px;
    background: rgba(249, 249, 249, 1);
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 5px 5px 0px 0px;
}
.gt-panel.gt-panel-orange .gt-panel-head {
    background: #e47203;
    color: rgba(255, 255, 255, 1.00);
}
.gt-panel.gt-panel-green .gt-panel-head {
    background: #499202;
    color: rgba(255, 255, 255, 1.00);
}
.gt-panel.gt-panel-default .gt-panel-head {
    background: #f2f2f2;
    color: #353535;
    border-bottom: 0px;
    width: 100%;
    float: left;
    border: 1px solid #DDDDDD;
    border-bottom: 0px;
}
.gt-panel.gt-panel-blue .gt-panel-head {
    background-color: rgb(8, 156, 190);
    color: white;
    border-color: rgb(8, 156, 190);
    border-bottom: 0px;
}
.gt-panel {
    margin-bottom: 20px;
    width: 100%;
    float: left;
}
.gt-panel .gt-panel-head {
    padding: 15px;
}
.gt-panel .gt-panel-head .gt-panel-title,
.gt-panel .gt-panel-border-green .gt-panel-title {
    font-size: 16px;
}
.gt-panel .gt-panel-head .gt-panel-title,
.gt-panel .gt-panel-border-orange .gt-panel-title {
    font-size: 16px;
}
.gt-panel .gt-panel-body {
    padding: 10px 15px;
    border-top: none !important;
    background: rgba(249, 249, 249, 1);
    box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    -webkit-box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    -moz-box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    width: 100%;
    float: left;
    border: 1px solid #DDDDDD;
}
.grey-bg {  
    background-color: #F5F7FA;
}

a, a:hover, a:focus, a:active {
    text-decoration: none;
    outline: none;
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.section-title-border {
    background-color: #000;
    height: 1 3px;
    width: 44px;
}
.section-title-border-white {
    background-color: #fff;
    height: 2px;
    width: 100px;
}
.text_custom {
    color: green;
    font-size: 20px;
    font-weight: 600;
    font-family: "poppins";
    padding: 10px;
}
.text-paragraph {
    font-size: 16px;
    font-weight: 300;
    font-family: "poppins";
    padding: 10px;
    color:black;
    line-height: 30px;
    text-align: justify;
}

.about_icon i {
    font-size: 22px;
    height: 65px;
    width: 65px;
    line-height: 65px;
    display: inline-block;
    background: #fff;
    border-radius: 35px;
    color: green;
    box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
}

.about_header_main .about_heading {
    max-width: 450px;
    font-size: 24px;
}

.about_icon span {
    position: relative;
    top: -10px;
}

.about_content_box_all {
    padding: 28px;
}

.img-fluid{
    border-radius: 14px;
    width: fit-content;
}
.text-desc{
    color: black;
    font-size: 16px;
    font-weight: 300;
    font-family: "poppins";
    padding: 10px;
    line-height: 30px;
}
.text-capitalize{
    color: red;
    font-size: 16px;
    font-weight: 500;
    font-family: "poppins";
    padding: 10px;
    line-height: 30px;
}