.bannerview{
    width: 100%;
    margin-top: 77px;
    border-radius: 14px;
}
.topbar{
    height: 50px;
}
.boxview{
    margin-top: 20px;
}
.headerTitle{
    color: red;
    font-size: 24px;
    font-weight: 700;
}
.card-title{
    color: green;
    font-size: 20px;
    font-weight: 500;
    font-family: "poppins";
}
.btnview{
    border-radius: 6px;
}
.card-text{
    color: black;
    font-size: 16px;
    font-weight: 500;
    font-family: "Raleway";
    line-height: 35px;
    margin-bottom: 10px;
}
.cardstyle{
    border-radius: 10px;
    background-color:#F3F3F1;
    margin-bottom: 10px;
}
.gt-body {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background: rgba(249, 249, 249, 1);
    border: 1px solid rgba(225, 225, 225, 1.00);
    box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
}
.gt-panel-border-green {
    border-top: 8px solid #499202;
    padding: 15px;
    margin-bottom: 1px;
    background: rgba(249, 249, 249, 1);
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 5px 5px 0px 0px;
}
.gt-panel-border-orange {
    border-top: 8px solid #e47203;
    padding: 15px;
    margin-bottom: 1px;
    background: rgba(249, 249, 249, 1);
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 5px 5px 0px 0px;
}
.gt-panel.gt-panel-orange .gt-panel-head {
    background: #e47203;
    color: rgba(255, 255, 255, 1.00);
}
.gt-panel.gt-panel-green .gt-panel-head {
    background: #499202;
    color: rgba(255, 255, 255, 1.00);
}
.gt-panel.gt-panel-default .gt-panel-head {
    background: #f2f2f2;
    color: #353535;
    border-bottom: 0px;
    width: 100%;
    float: left;
    border: 1px solid #DDDDDD;
    border-bottom: 0px;
}
.gt-panel.gt-panel-blue .gt-panel-head {
    background-color: rgb(8, 156, 190);
    color: white;
    border-color: rgb(8, 156, 190);
    border-bottom: 0px;
}
.gt-panel {
    margin-bottom: 20px;
    width: 100%;
    float: left;
}
.gt-panel .gt-panel-head {
    padding: 15px;
}
.gt-panel .gt-panel-head .gt-panel-title,
.gt-panel .gt-panel-border-green .gt-panel-title {
    font-size: 16px;
}
.gt-panel .gt-panel-head .gt-panel-title,
.gt-panel .gt-panel-border-orange .gt-panel-title {
    font-size: 16px;
}
.gt-panel .gt-panel-body {
    padding: 10px 15px;
    border-top: none !important;
    background: rgba(249, 249, 249, 1);
    box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    -webkit-box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    -moz-box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    width: 100%;
    float: left;
    border: 1px solid #DDDDDD;
}
.grey-bg {  
    background-color: #F5F7FA;
}
.workcard{
    border-radius: 10px;
    background-color:#F9EBEA;
    margin-bottom: 10px;
}
.workcardimage{
    width: 150px;
    height: 100px;
    border-radius: 6px;
    margin-right: 10px;    
}
.workHeader{
    color: green;
    font-size: 20px;
    font-weight: 600;
    font-family: "poppins";
    padding: 10px;
}
.workStepHeader{
    color: red;
    font-size: 24px;
    font-weight: 600;
    font-family: "poppins";
    padding: 10px;
}
.testimonials {
    padding: 30px 0;
  }
  .testimonials h3 {
    margin-bottom: 20px;
  }
  .testimonials .card {
    border-bottom: 3px #ABEBC6 solid !important;
    transition: 0.5s;
    margin-top: 60px;
  }
  .testimonials .card i {
    background-color: #ABEBC6;
    color: #ffffff;
    width: 75px;
    height: 75px;
    line-height: 75px;
    margin: -40px auto 0 auto;
  }