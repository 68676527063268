.hero-header {
    background: linear-gradient(rgba(2, 71, 19, 0.85), rgba(62, 26, 9, 0.85)), url(../../assets/banner-1.jpg) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 400px;
    align-content: center;
    border-radius: 14px;
}
.text-header{
    color: white;
    font-size: 24px;
    font-weight: 600;
    font-family: "poppins";
    padding: 10px;
    padding-top: 100px;
}

.gt-body {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background: rgba(249, 249, 249, 1);
    border: 1px solid rgba(225, 225, 225, 1.00);
    box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
}
.gt-panel-border-green {
    border-top: 8px solid #499202;
    padding: 15px;
    margin-bottom: 1px;
    background: rgba(249, 249, 249, 1);
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 5px 5px 0px 0px;
}
.gt-panel-border-orange {
    border-top: 8px solid #e47203;
    padding: 15px;
    margin-bottom: 1px;
    background: rgba(249, 249, 249, 1);
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 5px 5px 0px 0px;
}
.gt-panel.gt-panel-orange .gt-panel-head {
    background: #e47203;
    color: rgba(255, 255, 255, 1.00);
}
.gt-panel.gt-panel-green .gt-panel-head {
    background: #499202;
    color: rgba(255, 255, 255, 1.00);
}
.gt-panel.gt-panel-default .gt-panel-head {
    background: #f2f2f2;
    color: #353535;
    border-bottom: 0px;
    width: 100%;
    float: left;
    border: 1px solid #DDDDDD;
    border-bottom: 0px;
}
.gt-panel.gt-panel-blue .gt-panel-head {
    background-color: rgb(8, 156, 190);
    color: white;
    border-color: rgb(8, 156, 190);
    border-bottom: 0px;
}
.gt-panel {
    margin-bottom: 20px;
    width: 100%;
    float: left;
}
.gt-panel .gt-panel-head {
    padding: 15px;
}
.gt-panel .gt-panel-head .gt-panel-title,
.gt-panel .gt-panel-border-green .gt-panel-title {
    font-size: 16px;
}
.gt-panel .gt-panel-head .gt-panel-title,
.gt-panel .gt-panel-border-orange .gt-panel-title {
    font-size: 16px;
}
.gt-panel .gt-panel-body {
    padding: 10px 15px;
    border-top: none !important;
    background: rgba(249, 249, 249, 1);
    box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    -webkit-box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    -moz-box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    width: 100%;
    float: left;
    border: 1px solid #DDDDDD;
}
.grey-bg {  
    background-color: #F5F7FA;
}
.text_custom {
    color: green;
    font-size: 20px;
    font-weight: 600;
    font-family: "poppins";
}
.text-paragraph {
    font-size: 16px;
    font-weight: 300;
    font-family: "poppins";
    color:black;
    text-align: justify;
    padding-left: 10px;
}
p {
    margin-top: 0; 
}
.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }  
  .cards {
    overflow: hidden;
    box-shadow: 0px 2px 20px #d7dfe2;
    background: white;
    border-radius: 0.5rem;
    position: relative;
    width: 350px;
    margin: 1rem;
    transition: 250ms all ease-in-out;
    cursor: pointer;
  }  
  .cards:hover {
    transform: scale(1.05);
    box-shadow: 0px 2px 40px #d7dfe2;
  }  
  .banner-img {
    position: absolute;
    object-fit: cover;
    height: 14rem;
    width: 100%;
  }  
  .category-tag {
    font-size: 0.8rem;
    font-weight: bold;
    color: white;
    background: red;
    padding: 0.5rem 1.3rem 0.5rem 1rem;
    text-transform: uppercase;
    position: absolute;
    z-index: 1;
    top: 1rem;
    border-radius: 0 2rem 2rem 0;
  }  
  .popular {
    background: #ef257a;
  }  
  .technology {
    background: #651fff;
  }  
  .psychology {
    background: #e85808;
  }  
  .cards-body {
    margin: 15rem 1rem 1rem 1rem;
  }  
  .blog-hashtag {
    font-size: 0.9rem;
    font-weight: 500;
    color: #4d97b2;
  }  
  .blog-title {
    line-height: 1.5rem;
    margin: 1rem 0 0.5rem;
  }  
  .blog-description {
    color: #616b74;
    font-size: 0.9rem;
    font-family: "poppins";
    font-weight: 300;
    line-height: 25px;
    text-align: justify;
  }  
  .cards-profile {
    display: flex;
    margin-top: 2rem;
    align-items: center;
  }
  .profile-img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
  }  
  .cards-profile-info {
    margin-left: 1rem;
  }  
  .profile-name {
    font-size: 1rem;
    font-weight: 300;
    font-family: "poppins";
  }  
  .profile-followers {
    color: #616b74;
    font-size: 0.7rem;
    font-weight: 300;
    font-family: "poppins";
  }
  .story-title{
    font-family: "poppins";
    font-size: 14px;
    color: red;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  