.hero-header {
    background: linear-gradient(rgba(2, 71, 19, 0.85), rgba(62, 26, 9, 0.85)), url(../../assets/banner-1.jpg) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 400px;
    align-content: center;
    border-radius: 14px;
}
.text-header{
    color: white;
    font-size: 24px;
    font-weight: 600;
    font-family: "poppins";
    padding: 10px;
    padding-top: 100px;
}

.gt-body {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background: rgba(249, 249, 249, 1);
    border: 1px solid rgba(225, 225, 225, 1.00);
    box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
}
.gt-panel-border-green {
    border-top: 8px solid #499202;
    padding: 15px;
    margin-bottom: 1px;
    background: rgba(249, 249, 249, 1);
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 5px 5px 0px 0px;
}
.gt-panel-border-orange {
    border-top: 8px solid #e47203;
    padding: 15px;
    margin-bottom: 1px;
    background: rgba(249, 249, 249, 1);
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 5px 5px 0px 0px;
}
.gt-panel.gt-panel-orange .gt-panel-head {
    background: #e47203;
    color: rgba(255, 255, 255, 1.00);
}
.gt-panel.gt-panel-green .gt-panel-head {
    background: #499202;
    color: rgba(255, 255, 255, 1.00);
}
.gt-panel.gt-panel-default .gt-panel-head {
    background: #f2f2f2;
    color: #353535;
    border-bottom: 0px;
    width: 100%;
    float: left;
    border: 1px solid #DDDDDD;
    border-bottom: 0px;
}
.gt-panel.gt-panel-blue .gt-panel-head {
    background-color: rgb(8, 156, 190);
    color: white;
    border-color: rgb(8, 156, 190);
    border-bottom: 0px;
}
.gt-panel {
    margin-bottom: 20px;
    width: 100%;
    float: left;
}
.gt-panel .gt-panel-head {
    padding: 15px;
}
.gt-panel .gt-panel-head .gt-panel-title,
.gt-panel .gt-panel-border-green .gt-panel-title {
    font-size: 16px;
}
.gt-panel .gt-panel-head .gt-panel-title,
.gt-panel .gt-panel-border-orange .gt-panel-title {
    font-size: 16px;
}
.gt-panel .gt-panel-body {
    padding: 10px 15px;
    border-top: none !important;
    background: rgba(249, 249, 249, 1);
    box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    -webkit-box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    -moz-box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
    width: 100%;
    float: left;
    border: 1px solid #DDDDDD;
}
.grey-bg {  
    background-color: #F5F7FA;
}
.text_custom {
    color: green;
    font-size: 20px;
    font-weight: 600;
    font-family: "poppins";
}
.text_paragraph {
    font-size: 16px;
    font-weight: 300;
    font-family: "poppins";
    color:black;
    text-align: justify;
    padding-left: 10px;
}
p {
    margin-top: 0; 
}
.gt-login-form {
    padding: 15px 10px;
    margin-top: 60px;
    margin-bottom: 20px;
    border-radius: 5px 5px 0px 0px;
}
.gt-sign-up {
    padding: 15px 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px 5px 0px 0px;
}
.inputlabel {
    display: inline-block;
    max-width: 100%;
    font-size: 16px;
    font-weight: 500;
}
 
.sections {
  position: relative;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.sections .container {
  position: relative;
  width: 800px;
  height: 500px;
  background: #fff;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.sections .container .user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.sections .container .user .imgBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: #000;
  transition: 0.5s;
}

.sections .container .user .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sections .container .user .formBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  transition: 0.5s;
}

.sections .container .user .formBx form h2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  color: #555;
}

.sections .container .user .formBx form input {
  position: relative;
  width: 100%;
  padding: 10px;
  background: #f5f5f5;
  color: #333;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
}

.sections .container .user .formBx form input[type='submit'] {
  max-width: 100px;
  background: green;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.5s;
}

.sections .container .user .formBx form .signup {
  position: relative;
  margin-top: 20px;
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "poppins";
  letter-spacing: 1px;
}

.sections .container .user .formBx form .signup a {
  font-weight: 600;
  text-decoration: none;
  color: green;
}

.sections .container .signupBx {
  pointer-events: none;
}

.sections .container.active .signupBx {
  pointer-events: initial;
}

.sections .container .signupBx .formBx {
  left: 100%;
}

.sections .container.active .signupBx .formBx {
  left: 0;
}

.sections .container .signupBx .imgBx {
  left: -100%;
}

.sections .container.active .signupBx .imgBx {
  left: 0%;
}

.sections .container .signinBx .formBx {
  left: 0%;
}

.sections .container.active .signinBx .formBx {
  left: 100%;
}

.sections .container .signinBx .imgBx {
  left: 0%;
}

.sections .container.active .signinBx .imgBx {
  left: -100%;
}

@media (max-width: 991px) {
  .sections .container {
    max-width: 400px;
  }

  .sections .container .imgBx {
    display: none;
  }
  .sections .container .user .formBx {
    width: 100%;
  }
} 
.form-group{
  margin-bottom: 30px;
  width: 350px;
}
.form-control{
  height: 50px;
  border-radius: 8px;
}
.btn-submit{
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background-color: darkgreen;
  color: #fff;
  border-width: 0px;
  margin-bottom: 20px;
}
.register-text{
  color: red;
  text-align: center;
}
.forgotpassword-text{
  text-align: center;
  color: red;
  margin-bottom: -10px;
}