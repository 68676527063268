  .logo {
    width: auto;
    height: 70px;
  }
  .header {
      background-color: #fff;
      box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
      position: fixed;
      width: 100%;
      z-index: 3;
  }
  
  .header ul {
      margin: 0;
      padding: 0;
      list-style: none;
      overflow: hidden;
      background-color: #fff;
  }
  
  .header li a {
      display: block;
      padding: 20px 20px;
      text-decoration: none;
      color: black;
      font-weight: 500;
  }
  
  .header li a:hover,
  .header .menu-btn:hover {
      background-color: #f4f4f4;
  }
  
  .header .logo {
      display: block;
      float: left;
      font-size: 2em;
      padding: 10px 20px 10px 20px;
      text-decoration: none;
  }

  .header .menu {
      clear: both;
      max-height: 0;
      transition: max-height .2s ease-out;
  }
  
  .header .menu-icon {
      cursor: pointer;
      display: inline-block;
      float: right;
      padding: 28px 20px;
      position: relative;
      user-select: none;
  }
  
  .header .menu-icon .navicon {
      background: #333;
      display: block;
      height: 2px;
      position: relative;
      transition: .2s ease-out;
      width: 18px;
  }
  
  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
      background: #333;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      transition: all .2s ease-out;
      width: 100%;
  }
  
  .header .menu-icon .navicon:before {
      top: 5px;
  }
  
  .header .menu-icon .navicon:after {
      top: -5px;
  }
   
  .header .menu-btn {
      display: none;
  }
  
  .header .menu-btn:checked ~ .menu {
      max-height: 500px;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon {
      background: transparent;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:before {
      transform: rotate(-45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:after {
      transform: rotate(45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
      top: 0;
  }
 
  @media (min-width: 48em) {
      .header li {
          float: left;
      }
      .header li a {
          padding: 22px 14px;
      }
      .header .menu {
          clear: none;
          float: right;
          max-height: none;
      }
      .header .menu-icon {
          display: none;
      }
  }

  .bg-footer {
    background-color: rgb(0, 61, 0);
    padding: 50px 0 30px;
    font-family: "poppins";
    font-weight: 500;
}
.footer-heading {
    letter-spacing: 2px;
}

.footer-link a {
    color: #fff;
    line-height: 40px;
    font-size: 14px;
    font-family: "poppins";
    font-weight: 300;
    transition: all 0.5s;
}

.footer-link a:hover {
    color: red;
}

.contact-info {
    color: #fff;
    font-size: 14px;
    font-family: "poppins";
    font-weight: 300;
}

.footer-social-icon {
    font-size: 15px;
    height: 34px;
    width: 34px;
    line-height: 34px;
    border-radius: 3px;
    text-align: center;
    display: inline-block;
}

.footer-alt {
    color: #acacac;
}

.footer-heading {
    position: relative;
    padding-bottom: 12px;
    font-family: "poppins";
    font-weight: 500;
    margin-top: 15px;
}

.footer-heading:after {
    content: '';
    width: 25px;
    border-bottom: 1px solid #FFF;
    position: absolute;
    left: 0;
    font-family: "poppins";
    bottom: 0;
    display: block;
    border-bottom: 1px solid red;
}
.footer-text{
    color: #fff;
    font-family: "poppins";
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 2px;
}