.hero-header {
    background: linear-gradient(rgba(2, 71, 19, 0.85), rgba(62, 26, 9, 0.85)), url(../../assets/banner-1.jpg) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 400px;
    align-content: center;
}
.text-header{
    color: white;
    font-size: 24px;
    font-weight: 600;
    font-family: "poppins";
    padding: 10px;
    padding-top: 100px;
}
.form-group{
    margin-bottom: 30px;
    width: 300px;
  }
