.align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}
.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}
.aiz-user-sidenav-wrap {
    -ms-flex: 0 0 265px;
    flex: 0 0 265px;
    max-width: 265px;
    -webkit-box-shadow: 0 0 13px 0 rgba(2, 39, 1, 0.05);
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
    background: #fff;
    border-radius: 4px;
    border: 1px solid #ebedf2;
}
.shadow-none {
    box-shadow: none !important;
}
.z-1 {
    z-index: 1 !important;
}
.c-scrollbar-light, .uppy-Dashboard-files, .bootstrap-select .dropdown-menu .inner {
    scrollbar-color: rgba(0, 56, 19, 0.08);
    scrollbar-width: thin;
}
.aiz-side-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
user agent stylesheet
ul {
    list-style-type: disc;
}
.text-left {
    text-align: left !important;
}
.aiz-side-nav-list .aiz-side-nav-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.aiz-user-sidenav .aiz-side-nav-list .aiz-side-nav-link {
    color: black;
    font-weight: 500;
    font-size: 0.8125rem;
    border-left: 3px solid transparent;
}
.aiz-side-nav-list .aiz-side-nav-link:hover, .aiz-side-nav-list .aiz-side-nav-link.level-2-active, .aiz-side-nav-list .aiz-side-nav-link.level-3-active, .aiz-side-nav-list .aiz-side-nav-link.active {
    color: #fff;
    background-color: rgb(240, 47, 47);
}
.aiz-side-nav-list .aiz-side-nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding: 10px 25px;
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: capitalize;
    color: #a2a3b7;
}
a {
    color: green;
    text-decoration: none;
    background-color: transparent;
}
user agent stylesheet
a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
}
user agent stylesheet
li {
    text-align: -webkit-match-parent;
}
.aiz-side-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
user agent stylesheet
ul {
    list-style-type: disc;
}
.text-left {
    text-align: left !important;
}
body {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: green;
}
.dashboardView{
    padding-top: 50px;
}