.hero-header {
  background: linear-gradient(rgba(2, 71, 19, 0.85), rgba(62, 26, 9, 0.85)), url(../../assets/banner-1.jpg) center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 400px;
  align-content: center;
  border-radius: 14px;
}
.text-header{
  color: white;
  font-size: 24px;
  font-weight: 600;
  font-family: "poppins";
  padding: 10px;
  padding-top: 100px;
}

.gt-body {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background: rgba(249, 249, 249, 1);
  border: 1px solid rgba(225, 225, 225, 1.00);
  box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
}
.gt-panel-border-green {
  border-top: 8px solid #499202;
  padding: 15px;
  margin-bottom: 1px;
  background: rgba(249, 249, 249, 1);
  border-left: 1px solid #DDDDDD;
  border-right: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
  border-radius: 5px 5px 0px 0px;
}
.gt-panel-border-orange {
  border-top: 8px solid #e47203;
  padding: 15px;
  margin-bottom: 1px;
  background: rgba(249, 249, 249, 1);
  border-left: 1px solid #DDDDDD;
  border-right: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
  border-radius: 5px 5px 0px 0px;
}
.gt-panel.gt-panel-orange .gt-panel-head {
  background: #e47203;
  color: rgba(255, 255, 255, 1.00);
}
.gt-panel.gt-panel-green .gt-panel-head {
  background: #499202;
  color: rgba(255, 255, 255, 1.00);
}
.gt-panel.gt-panel-default .gt-panel-head {
  background: #f2f2f2;
  color: #353535;
  border-bottom: 0px;
  width: 100%;
  float: left;
  border: 1px solid #DDDDDD;
  border-bottom: 0px;
}
.gt-panel.gt-panel-blue .gt-panel-head {
  background-color: rgb(8, 156, 190);
  color: white;
  border-color: rgb(8, 156, 190);
  border-bottom: 0px;
}
.gt-panel {
  margin-bottom: 20px;
  width: 100%;
  float: left;
}
.gt-panel .gt-panel-head {
  padding: 15px;
}
.gt-panel .gt-panel-head .gt-panel-title,
.gt-panel .gt-panel-border-green .gt-panel-title {
  font-size: 16px;
}
.gt-panel .gt-panel-head .gt-panel-title,
.gt-panel .gt-panel-border-orange .gt-panel-title {
  font-size: 16px;
}
.gt-panel .gt-panel-body {
  padding: 10px 15px;
  border-top: none !important;
  background: rgba(249, 249, 249, 1);
  box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
  -webkit-box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
  -moz-box-shadow: 0px 2px 0px 0px rgba(208, 208, 208, 1);
  width: 100%;
  float: left;
  border: 1px solid #DDDDDD;
}
.grey-bg {  
  background-color: #F5F7FA;
}
.text_custom {
  color: green;
  font-size: 20px;
  font-weight: 600;
  font-family: "poppins";
}
.text_paragraph {
  font-size: 16px;
  font-weight: 300;
  font-family: "poppins";
  color:black;
  text-align: justify;
  padding-left: 10px;
}
p {
  margin-top: 0; 
}
.gt-login-form {
  padding: 15px 10px;
  margin-top: 60px;
  margin-bottom: 20px;
  border-radius: 5px 5px 0px 0px;
}
.gt-sign-up {
  padding: 15px 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px 5px 0px 0px;
}
.btn-tabs{
  width: 200px;
  height: 40px;
  margin-left: 10px;
  border-radius: 25px;
margin-top: 20px;
}
.active-tabs{
  background-color: #499202;
  color: #FFF;
  border-color: red;
}
.inactive-tabs{
color: #000;
}
.tab-container{
margin-top: 20px;
margin-right: 20px;
}
.input-label{
  margin-bottom: 10px;
  margin-top: 20px;
}
.form-input-control{
  height: 40px;
  width: 100%;
  border-color: gray;
}
.btn-registersubmit{
  height: 50px;
  border-radius: 8px;
  width: 100%;
  border-width: 0px;
  background-color: #006400;
  color: #FFF;
  margin-top: 30px;
}