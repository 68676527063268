.hero-header {
    background: linear-gradient(rgba(2, 71, 19, 0.85), rgba(62, 26, 9, 0.85)), url(../../assets/banner-1.jpg) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 400px;
    align-content: center;
}
.text-header{
    color: white;
    font-size: 24px;
    font-weight: 600;
    font-family: "poppins";
    padding: 10px;
	padding-top: 100px;
}

.section-padding {
	padding: 100px 0;
}
.section-title {
	text-align: center;
	line-height: 1.5;
	margin-bottom: 65px;
}
.sub-title {
    color: red;
	font-size: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-family: "poppins";
    font-weight: 600;
}

.contact-section {
	position: relative;
    z-index: 1;
    /* background: url(../../assets/banner-1.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.contact-form .form-group {
	margin-bottom: 30px;
}

.contact-form form .form-control {
    border: 0;
    width: 100%;
    height: 55px;
    font-size: 16px;
    font-weight: 500;
    padding: 12px 20px;
    border-radius: 5px;
	border: 1px solid transparent;
    border-left: 2px solid green;
    background-color: #ffffff;
    -webkit-box-shadow: 0 5px 20px rgba(218, 225, 232, 0.8);
    box-shadow: 0 5px 20px rgba(218, 225, 232, 0.8);
}

.contact-form form .form-control:focus {
	border: none;
	outline: none;
	border: 1px solid green;
    border-left: 2px solid green;
}

.contact-form form textarea.form-control {
	height: auto;
	padding-top: 15px;
	line-height: initial;
}

.contact-info-wrapper {
	padding: 100px 0;
}

.contact-info-wrapper .section-title {
	text-align: center;
	margin-bottom: 45px;
}

.contact-info-content {
	padding: 45px 20px;
	text-align: center;
	border-radius: 5px;
	border-top: 3px solid green;
	background-color: #f3fff3;
	-webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05), 0 5px 15px rgba(0, 0, 0, 0.03);
	box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05), 0 5px 15px rgba(0, 0, 0, 0.03);
	margin-top: 30px;
}

.contact-info-content h5 {
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 10px;
}

.contact-info-content a {
	display: block;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.5;
}

.contact-info-content p {
	font-size: 16px;
	margin-bottom: 12px;
}

.contact-section .form-message.success {
	color: #fff;
	padding: 10px 15px;
	border-radius: 5px;
	background-color: #03b103;
	margin-bottom: 30px;
}

.contact-section .form-message.error {
	color: #fff;
	padding: 10px 15px;
	border-radius: 5px;
	background-color: #ff4d15;
	margin-bottom: 30px;
}
.contact-container{
	margin-top: 30px;
	margin-left: 20px;
}
.btn-contact-submit{
	padding-left: 10px;
	padding-right: 10px;
	height: 40px;
	border-radius: 8px;
	background-color: darkgreen;
	color: #fff;
	border-width: 0px;
	margin-bottom: 20px;
}