.hero-header {
    background: linear-gradient(rgba(2, 71, 19, 0.85), rgba(62, 26, 9, 0.85)), url(../../assets/banner-1.jpg) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 400px;
    align-content: center;
}
.text-header{
    color: white;
    font-size: 24px;
    font-weight: 600;
    font-family: "poppins";
    padding: 10px;
    padding-top: 100px;
}
#price {
    text-align: center;
  }
  .plan {
    display: inline-block;
    margin: 10px 1%;
    font-family: 'Lato', Arial, sans-serif;
  } 
  .plan-inner {
    background: #fff;
    margin: 0 auto;
    min-width: 280px;
    max-width: 100%;
    position:relative;
  }  
  .entry-title {
    background: #53CFE9;
    height: 140px;
    position: relative;
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
  }  
  .entry-title>h3 {
    background: #20BADA;
    font-size: 20px;
    padding: 5px 0;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
  }  
  .entry-title .price {
    position: absolute;
    bottom: -25px;
    background: #20BADA;
    height: 95px;
    width: 95px;
    margin: 0 auto;
    left: 0;
    right: 0;
    overflow: hidden;
    border-radius: 50px;
    border: 5px solid #fff;
    line-height: 80px;
    font-size: 28px;
    font-weight: 700;
  }  
  .price span {
    position: absolute;
    font-size: 9px;
    bottom: -10px;
    left: 30px;
    font-weight: 400;
  }  
  .entry-content {
    color: #323232;
    font-weight: 400;
    font-family: "poppins";
  }  
  .entry-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
  }  
  .entry-content li {
    border-bottom: 1px solid #E5E5E5;
    padding: 10px 0;
  }  
  .entry-content li:last-child {
    border: none;
  }  
  .btn {
    text-align: center;
  }  
  .btn a {
    background: darkgreen;
    padding: 10px 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    border-radius: 10px;
  }
  .hot {
      position: absolute;
      top: -7px;
      background: #F80;
      color: #fff;
      text-transform: uppercase;
      z-index: 2;
      padding: 2px 5px;
      font-size: 9px;
      border-radius: 2px;
      right: 10px;
      font-weight: 700;
  }
  .basic .entry-title {
    background: #75DDD9;
  }  
  .basic .entry-title > h3 {
    background: #44CBC6;
  }  
  .basic .price {
    background: #44CBC6;
  }  
  .standard .entry-title {
    background: #4484c1;
  }
  .standard .entry-title > h3 {
    background: #3772aa;
  }  
  .standard .price {
    background: #3772aa;
  }  
  .ultimite .entry-title > h3 {
    background: #DD4B5E;
  }  
  .ultimite .entry-title {
    background: #F75C70;
  }  
  .ultimite .price {
    background: #DD4B5E;
  }
